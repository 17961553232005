import axios from "axios";
import {API_BASE, API_KEY} from "../config";

export default class AirtableService {

    constructor(props) {
    }

    getHeader() {
        var headers = {
            'Authorization': 'Bearer '+API_KEY
        };
        return headers;
    }

    getOptions() {
        return {
            headers: this.getHeader()
        };
    }

    getUrl() {
        return "https://api.airtable.com/v0/"+API_BASE+"/";
    }

    loadProductslist(offset, records) {
      return axios.get(this.getUrl()+'Verkn%C3%BCpfte%20Produkte?view=Grid%20view'+(offset ? "&offset="+offset : ""), this.getOptions())
        .then((response) => {

          if (typeof records === "undefined") {
            records = [];
          }
          if (typeof response.data.offset !== "undefined") {
            return this.loadProductslist(response.data.offset, [...records, ...response.data.records]);
          }

          return [...response.data.records, ...records].map((d) => {
            let f = d.fields;
            f.id = d.id;
            return f;
          });
        });
    }

    loadAgenturTodos(offset, records) {
      return axios.get(this.getUrl()+'Aufgabenliste%20Agentur?view=Grid%20view'+(offset ? "&offset="+offset : ""), this.getOptions())
        .then((response) => {

          if (typeof records === "undefined") {
            records = [];
          }
          if (typeof response.data.offset !== "undefined") {
            return this.loadAgenturTodos(response.data.offset, [...records, ...response.data.records]);
          }

          return [...response.data.records, ...records].map((d) => {
            let f = d.fields;
            f.id = d.id;
            return f;
          });
        });
    }

    loadCraftsmenTodos(offset, records) {
        return axios.get(this.getUrl()+'Aufgabenliste%20HW?view=Grid%20view'+(offset ? "&offset="+offset : ""), this.getOptions())
          .then((response) => {

              if (typeof records === "undefined") {
                  records = [];
              }
              if (typeof response.data.offset !== "undefined") {
                  return this.loadCraftsmenTodos(response.data.offset, [...records, ...response.data.records]);
              }

              return [...response.data.records, ...records].map((d) => {
                  let f = d.fields;
                  f.id = d.id;
                  return f;
              });
          });
    }

    loadChooseSettings(offset, records) {
        return axios.get(this.getUrl()+'Auswahlm%C3%B6glichkeiten?view=Grid%20view'+(offset ? "&offset="+offset : ""), this.getOptions())
            .then((response) => {

                if (typeof records === "undefined") {
                    records = [];
                }
                if (typeof response.data.offset !== "undefined") {
                    return this.loadChooseSettings(response.data.offset, [...records, ...response.data.records]);
                }

                return [...response.data.records, ...records].map((d) => {
                    let f = d.fields;
                    f.id = d.id;
                    return f;
                });
            });
    }

    loadConfigurationSide(offset, records) {
        return axios.get(this.getUrl()+'Webseitenkonfiguration?view=Grid%20view'+(offset ? "&offset="+offset : ""), this.getOptions())
            .then((response) => {

                if (typeof records === "undefined" || records == null) {
                    records = [];
                }

                if (typeof response.data.offset !== "undefined") {
                    return this.loadConfigurationSide(response.data.offset, [...records, ...response.data.records]);
                }
                return [...records, ...response.data.records].map((d) => {
                    let f = d.fields;
                    f.id = d.id;
                    return f;
                });
            });
    }


}
