import React, {Component} from 'react';
import { Container, Row, Col } from 'reactstrap';
import QuestionType from "./QuestionType";
import Input from "reactstrap/lib/Input";
import { SketchPicker, CompactPicker, CirclePicker, PhotoshopPicker, MaterialPicker, SwatchesPicker, TwitterPicker, HuePicker, ChromePicker, BlockPicker } from 'react-color';

class QuestionColor extends QuestionType {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  getTextValue() {
    const txt = this.getValue();
    if (txt == null || typeof txt === "undefined") {
      return "#ccc";
    }
    return txt;
  }

  render() {
    return <Container fluid={true} className={"color-text-container "+(this.hasError() ? "error" : "")}>
      <Row>
        <Col className={"p-1"} md={12}>
          {!this.isFinished() && <PhotoshopPicker color={this.getTextValue()} onChangeComplete={(color) => this.setValue(color.hex)}  />}
          {this.isFinished() && <div className={"color-block"} style={{backgroundColor: this.getTextValue()}}>{this.getTextValue()}</div>}
          {this.renderErrors()}
        </Col>
      </Row>
    </Container>;
  }
};

QuestionColor.propTypes = {

};


export default QuestionColor;
