import React, { Component } from 'react';
import { hot } from 'react-hot-loader/root';
import FormularPage from "./pages/FormularPage";
import FormularConfigurationService from "./services/FormularConfigurationService";
import QuestionController from "./controller/QuestionController";
import DBService from "./services/DBService";
import AnswerPage from "./pages/AnswerPage";
import LoadingOverlay from "react-loading-overlay";
import { WEBCONFIG_TRADE_MODE } from "./config";
import LoginPage from "./pages/LoginPage";

const formularConfig = new FormularConfigurationService();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };

    this.loadConfiguration = this.loadConfiguration.bind(this);
  }

  getEditMode() {
    let theURL              = new URL(window.location.origin);             // create dummy url
    theURL.search           = window.location.hash.substring(1);

    let editMode = theURL.searchParams.get("editMode");
    if (editMode != null) {
      editMode = editMode.trim();
      editMode = editMode.toLowerCase();
      if (editMode == "true") {
        return true;
      }
    }
    return false;
  }

  getPage() {
      let theURL              = new URL(window.location.origin);             // create dummy url
      theURL.search           = window.location.hash.substring(1);

      let page = theURL.searchParams.get("page");
      if (page != null) {
        page = page.trim();
        page = page.toLowerCase();
      }
      return page;
  }

  loadConfiguration(cb) {
    const app = this;
    return formularConfig.loadConfigAndStore()
      .then(() => {

        cb.questionController = new QuestionController(cb);
        window.qctrl = cb.questionController;
        let currentCategory = null;
        let devmode = false;
        const category = cb.questionController.getCurrentHashParameter("category");
        if (category !== null) {
          currentCategory = category;
        }

        const devmode_p = cb.questionController.getCurrentHashParameter("devmode");
        if (devmode_p !== null) {
          devmode = true;
        }


        cb.setState({
          agenturTodos: formularConfig.getAgenturTodos(),
          devMode: devmode,
          products: formularConfig.getProducts(),
          craftsmenTodos: formularConfig.getCraftsmenTodos(),
          flatQuestions: formularConfig.getFlatQuestions(),
          flatQuestionsSorted: formularConfig.getFlatQuestionsSorted(),
          options: formularConfig.getOptions(),
          editMode: this.getEditMode(),
          currentCategory: currentCategory,
        });

        cb.questionController.updateQuestionVisibility();

        cb.setState({
          currentQuestion: cb.questionController.getFirstQuestion(),
        });

        let currentQuestion = null;
        const qid = cb.questionController.getCurrentHashParameter("question");
        if (qid !== null) {
          const question = cb.questionController.findQuestionById(qid);
          if (question != null) {
            currentQuestion = question;
          }
        }

        const cid = cb.questionController.getCurrentHashParameter("cid");
        const publicView = cb.questionController.getCurrentHashParameter("publicView");
        if (cid !== null) {
          cb.setState({
            cid: cid
          });
          cb.dbService = new DBService(cid, publicView);

          cb.dbService.loadState()
            .then((d) => {

              if (typeof d.trade === "undefined") {
                d.trade = "SHK";
              }

              if (d.trade !== WEBCONFIG_TRADE_MODE) {
                alert("Sie haben ein "+d.trade+" Kunde in einem "+WEBCONFIG_TRADE_MODE+" Konfigurator geöffnet und werden jetzt weitergeleitet.");
                let correct_endpoint = "https://webconfig.agentur-id.de/";
                if (d.trade === "EFG") {
                  correct_endpoint = "https://webconfig-efg.agentur-id.de/";
                }
                window.location = correct_endpoint+"#cid="+cid;
                return;
              }

              cb.setState({
                finished: (d.finished  === true) ? true: false,
                summaryConfig: d.summaryConfig,
                zohoCustomerData: (d.zoho_customer) ? d.zoho_customer : null,
                answers: d.answers,
                name: d.name,
                loading: false
              });
              app.setState({
                loading: false
              });
              cb.questionController.updateQuestionVisibility();
              if (currentQuestion != null) {
                cb.questionController.setCurrentQuestionFromOutside(currentQuestion);
              }
            })
            .catch((e) => {
              console.error(e);
              app.setState({
                loading: false
              });
              if (typeof e.response !== "undefined" &&
                e.response.status == 401) {
                window.location = "#page=login&redirect="+encodeURIComponent(window.location);
                window.location.reload();
                return;
              }
              cb.setState({
                loading: false,
                error: "Fehler beim laden der Configuration aus der Handwerker DB: "+e.message
              });
            });



        }
        else {
          // Show Error
          app.setState({
            loading: false
          });
          cb.setState({
            loading: false
          });
        }

      });
  }

  render() {


    if (this.getPage() == "login") {
      return <LoginPage app={this} />;
    }
    else if (this.getPage() == "answers") {
      return <AnswerPage app={this} />;
    }
    return <FormularPage app={this} title="" />;
  }
}


export default hot(App);
