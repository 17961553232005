import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import QuestionType from "./QuestionType";
import Input from "reactstrap/lib/Input";
import FormGroup from "reactstrap/lib/FormGroup";
import { FaCheck } from 'react-icons/fa';
import Error from "../errors/Error";

class QuestionDomain extends QuestionType {
    constructor(props) {
        super(props);
        this.state = {
            validationError: null
        };
    }

    getTextValue() {
        let txt = this.getValue();
        if (txt == null && this.hasVorauswahl()) {
            txt = this.getVorauswahl();
        }
        return txt;
    }

    validateUrl(url) {
        const noScheme = /^[\w.-]+(?:\.[\w\.-]+)+[\w\-\._%~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(url);
        if (noScheme) {
            if (url.startsWith("www.")) {
                return false;
            }
            return true;
        }
        return false;
    }

    setAndCheckValue(value) {
        this.setValue(value);
        if (this.validateUrl(value)) {
            this.setState({validationError: null});
        }
        else {
            this.setState({validationError: "Bitte gib die Domain in der Form 'xyz.de' an - ohne http/https und ohne www"});
        }

    }

    renderValidationErrors()  {
        if (this.state.validationError) {
            return <Error error={{text: this.state.validationError, missing: true}} />;
        }
        return null;
    }

    render() {
        return <Container fluid={true} className={"text-container "+(this.hasError() ? "error" : "")}>
            <Row>
                <Col className={"p-1"} md={12}>
                    {this.isVorauswahl() && <FaCheck className={"vorauswahl_check"} onClick={(e) => this.onVorauswahlCheckClicked(e)}/>}
                    <Input type={"text"} value={this.getTextValue()} disabled={this.isFinished()} placeholder={this.placeholder()} onChange={(e) => this.setAndCheckValue(e.target.value)} name={"text"} />
                    {this.renderValidationErrors()}
                    {this.renderErrors()}
                </Col>
            </Row>
        </Container>;
    }
};

QuestionDomain.propTypes = {

};


export default QuestionDomain;
