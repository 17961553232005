import axios from "axios";
import {DB_API} from "../config";

export default class DBService {

    constructor(cid, publicView = false) {
        this.cid = cid;
        this.publicView = publicView;
    }

    setCid(cid) {
        this.cid = cid;
    }

    getCid() {
        return this.cid;
    }

    saveState(states, categories, state) {
        return axios.put(DB_API+this.getCid(), {answers: states, questions: categories, state: state}, {
            headers: {
                "Authorization": "Bearer "+this.getAuthToken()
            }
        });
    }

    getAuthToken() {
        return localStorage.getItem("webconfig_token");
    }

    isPublic() {
        return this.publicView;
    }

    loadState() {
        const api = (this.isPublic()) ? DB_API.replace("_sec", "") : DB_API;
        return axios.get(api+this.getCid(), {
            headers: {
                "Authorization": "Bearer "+this.getAuthToken()
            }
        })
            .then((d) => {
                if (d.status == 200) {
                    if (typeof d.data.answers === "undefined") {
                        // empty object if no question currently
                        return {answers: {}, trade: d.data.trade, name: d.data.zoho_id, zoho_customer: d.data.zoho_customer,  finished: d.data.finished};
                    }
                    let answers = d.data.answers;
                    if (Array.isArray(answers)) {
                        answers = {};
                    }
                    return {answers: answers, trade: d.data.trade, summaryConfig: d.data.summaryConfig, name: d.data.zoho_id, zoho_customer: d.data.zoho_customer, finished: d.data.finished};
                }
                throw Error("Can not load the answers from your configuration");
            });
    }

}
