import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { FaLessThan,FaTrash, FaDownload } from 'react-icons/fa';
import QuestionPageContainer from "./QuestionPageContainer";
import Question from "./Question";
import Input from "reactstrap/lib/Input";
import {FileManager, FileUploader} from "reactjs-file-uploader";
import Progress from "reactstrap/lib/Progress";
import Button from "reactstrap/lib/Button";
import { DOWNLOAD_API, UPLOAD_API } from "../../config";
import CommunicationComponent from "../communication/CommunicationComponent";
const { v4: uuidv4 } = require('uuid');

class QuestionGroup extends CommunicationComponent {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            toggleContainer: false,
            toggleUpload: false
        };

        this.uploadFiles = this.uploadFiles.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.onFileSelected = this.onFileSelected.bind(this);
        this.onPageChanged = this.onPageChanged.bind(this);

        this.registerEventFor("pageChanged", () => this.onPageChanged());
    }

    qCtrl() {
        return this.props.qctrl;
    }

    onPageChanged() {
        this.setState({toggleContainer: false, toggleUpload: false});
    }

    getQuestions() {
        return this.qCtrl().getCurrentQuestionsForPage();
    }

    getFirstQuestion() {
        return this.getQuestions()[0];
    }

    renderQuestions() {
        const list = [];

        this.getQuestions().map((q) => {
            list.push( <Question question={q} {...this.props}></Question> );
        });

        return list;
    }

    toggleCommentContainer() {
        this.setState({
            toggleContainer: !this.state.toggleContainer,
            toggleUpload: false
        });
    }

    onChangeComment(e) {
        const q = this.getFirstQuestion();
        this.qCtrl().setComment(q, e.target.value);
    }

    toggleUploadContainer() {
        this.setState({
            toggleUpload: !this.state.toggleUpload,
            toggleContainer: false
        });
    }

    uploadFiles(files) {
        return files.map((file) => this.uploadFile(file));
    }

    onFileUploaded(file) {
        this.setState({
            files: this.state.files.filter((f) => f.id != file.id)
        });
        const q = this.getFirstQuestion();
        this.qCtrl().appendUploadToQuestion(q, file);

    }

    uploadFile(file) {
        return (
          <FileUploader
            key={file.key}
            file={file}
            onUploadComplete={() => this.onFileUploaded(file)}
            autoUpload={true}
            url={UPLOAD_API}
            formData={{
                imagefile: file.fileInfo,
                id: file.id
            }}
            readFile
          >
              {QuestionGroup.fileProgress}
          </FileUploader>
        );
    }


    downloadFile(file) {
      const uri = DOWNLOAD_API+file.id;
      var link = document.createElement("a");
      // If you don't know the name or want to use
      // the webserver default set name = ''
      link.setAttribute('download', file.name);
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    deleteFile(file) {
      const q = this.getFirstQuestion();
      return this.qCtrl().removeFileFromQuestion(q, file);
    }

    getAllUploads() {
        const q = this.getFirstQuestion();
        return this.qCtrl().getUploadsForQuestion(q);
    }

    renderAllUploadedFiles() {
        return this.getAllUploads().map((u) => {
            return <div className={"upload-file-line row"}>
                <div className={"col-md-8 filename"}> {u.name}</div>
              <div className={"col-md-4"}><Button color={"none"} onClick={(e) => this.downloadFile(u)} size={"sm"}><FaDownload /></Button> <Button  onClick={(e) => this.deleteFile(u)}  color={"none"} size={"sm"}><FaTrash /></Button></div>
            </div>;
        });
    }

    onClickUploadFile(e) {
        document.getElementById("uploadinput").click();
    }

    renderCommentField() {
        const uploadCount = this.getAllUploads().length;
        const q = this.getFirstQuestion();
        const comment = this.qCtrl().getCommentsForQuestion(q);
        try {
            return <div className={"comment-container"}>
                <h5 className={"comments"}>
                    <span className={"upload-btn"} onClick={(e) => this.toggleUploadContainer()}>{uploadCount <= 0 ? null : "("+uploadCount+")"} Dateien</span>
                    <span onClick={(e) => this.toggleCommentContainer()}>{comment == null ? null : "(1)"} Kommentare</span>
                </h5>
                {this.state.toggleContainer &&
                <div className={"comment-textarea"}>
                    <Input type={"textarea"} name={"comment"} onChange={(e) => this.onChangeComment(e)} value={comment} />
                </div>}
                {this.state.toggleUpload &&
                <div className={"upload-container"}>
                    <input
                      type="file"
                      accept="*/*"
                      onChange={event => this.onFileSelected(event)}
                      multiple
                      id={"uploadinput"}
                      style={{display: "none"}}
                    />
                  <Button onClick={(e) => this.onClickUploadFile(e)} size={"sm"}>Datei hochladen</Button>
                    <div
                      className={"upload-block"}>
                        <FileManager
                          files={this.state.files}
                        >
                            {this.uploadFiles}
                        </FileManager>
                    </div>
                    <div className={"all-files"}>
                        {this.renderAllUploadedFiles()}
                    </div>
                </div>}
            </div>;
        }
        catch (e) {}
        return null;
    }

    render() {
        return <Container className={"p-0"} fluid={true}>
            <Row>
                <Col className={"p-1"} md={12}>
                    {this.renderCommentField()}
                    {this.renderQuestions()}
                </Col>
            </Row>
        </Container>
            ;
    }


    static fileProgress({

                    /*
                    References to the Event objects.
                    Initial state is null and each propert gets assigned on Event.
                     */
                    uploadReady,
                    uploadStart,
                    uploadProgress,
                    uploadComplete,
                    downloadStart,
                    downloadProgress,
                    downloadComplete,
                    error,
                    abort,
                    timeout,

                    /*
                    The sequential state of the request
                    enum {
                        uploadReady, uploadStart, uploadProgress, uploadComplete, downloadStart
                        downloadStart, downloadProgress, downloadComplete
                    }
                     */
                    requestState,

                    /*
                    Function references to start / abort request
                      */
                    startUpload,
                    abortRequest,

                    /*
                    Request Object reference (XMLHttpReqeust)
                     */
                    request,

                    /*
                    Response text Object (JSON)
                     */
                    response,

                    /*
                    Data of the file being uploaded (if readData props is true)
                     */
                    fileData,

                }) {
        return (
          <div className={"upload-file-line row"}>
              <div className={"filename col-md-6"}>{this.formData.imagefile.name}</div>
              <div className={" col-md-6"}>{uploadProgress && <Progress value={uploadProgress.loaded / uploadProgress.total * 100} >{Number(uploadProgress.loaded / uploadProgress.total * 100).toFixed(2)}</Progress>}</div>
          </div>
        );
    }

    static buildID(file) {
      let filename = file.name.toString();
      if (filename.length > 100) {
        filename = filename.substring(filename.length-100, filename.length);
      }
      return QuestionGroup.uniqueID()+"_"+filename;
    }

    static uniqueID() {
        return uuidv4().toString().substring(0,7);
    }

    onFileSelected(event) {
        const q = this.getFirstQuestion();
        const fileList = Array.from(event.target.files);
        const mapppedList = fileList.map((file) => {
                                    return {
                                        filename: file.name,
                                        fileInfo: file,
                                        id: QuestionGroup.buildID(file)
                                };
                        });

        this.setState({files: this.state.files.concat(mapppedList)});
    }
};

QuestionGroup.propTypes = {

};


export default QuestionGroup;
