import React, {Component} from 'react';
import Container from "reactstrap/lib/Container";
import Button from "reactstrap/lib/Button";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import ResultsAnswers from "./ResultAnswers";
import OrderConfirmation from "./OrderConfirmation";
import ResultsTodos from "./ResultTodos";
import CategoryOrderConfirmation from "./CategoryOrderConfirmation";

export default class ResultsPage extends Component {

    constructor(props) {
        super(props);

    }

    qCtrl() {
        return this.props.qctrl;
    }

    render() {
        return <Container fluid={true} className={"results-container"}>
            <Row className={"p-4"}>
                <Col md={12}>
                    <h1>Zusammenfassung</h1>
                    <ResultsTodos {...this.props}></ResultsTodos>
                    {this.qCtrl().isCategoryMode() && <CategoryOrderConfirmation {...this.props}></CategoryOrderConfirmation>}
                    {!this.qCtrl().isCategoryMode() && <OrderConfirmation {...this.props}></OrderConfirmation>}
                </Col>
            </Row>

            <div onClick={(e) => this.props.onCloseResults(e)} className={"results-close-button"}>X</div>
        </Container>;
    }
}
