import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { FaLessThan } from 'react-icons/fa';
import QuestionPageContainer from "./QuestionPageContainer";
import SamplePageContainer from "./SamplePageContainer";

class FormularSite extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    qCtrl() {
        return this.props.qctrl;
    }


    render() {
        return <Container className={"formularsite p-0"} fluid={true}>
                <Row>
                    <Col className={"questionwrapper p-5"} md={5}>
                        <QuestionPageContainer {...this.props}></QuestionPageContainer>
                    </Col>
                    <Col className={"samplecontainer p-4"} md={7}>
                        <SamplePageContainer {...this.props}></SamplePageContainer>
                    </Col>
                </Row>
            </Container>
        ;
    }
};

FormularSite.propTypes = {

};


export default FormularSite;
