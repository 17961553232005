import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import SubNavigation from "../components/navigation/SubNavigation";
import ProjectNavigation from "../components/navigation/ProjectNavigation";
import MainNavigation from "../components/navigation/MainNavigation";
import FormularSite from "../components/formular/FormularSite";
import QuestionController from "../controller/QuestionController";
import LoadingOverlay from 'react-loading-overlay';
import DBService from "../services/DBService";
import ResultsPage from "../components/results/ResultsPage";
import FinishedHeader from "../components/finished/FinishedHeader";
import EditModeHeader from "../components/finished/EditModeHeader";
import {WEBCONFIG_TRADE_MODE} from "../config.jsx";

class FormularPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            name: "",
            error: null,
            finished: false,
            editMode: false,
            currentQuestion: null,
            zohoCustomerData: null,
            categories: [],
            agenturTodos: {},
            craftsmenTodos: {},
            flatQuestions: {},
            answers: {},
            summaryConfig: {},
            cid: null,
            options: {},
            showResults: false,
            errors: {}
        };

        this.dbService = null;
        this.questionController = null;

        this.props.app.loadConfiguration(this);

    }

    onFormularAnswerChangedSuccfessfully() {
        return this.getDBService().saveState(this.state.answers, this.state.flatQuestions)
            .then(() => {

            });
    }

    getDBService() {
        return this.dbService;
    }

    render() {

        if (this.state.loading) {
            return <LoadingOverlay
                active={this.state.loading }
                spinner
                className={"overload-spinner"}
                text='Lade Formular ...'
            >
                <div className={"fullscreen-loader"}><br/></div>
            </LoadingOverlay>;
        }

        if (this.state.cid === null) {
            return  <Container fluid={true} className={"page-container error-no-cid-container p-0"}>
                <div className={"error-message"}>Es wurde leider kein Handwerker ausgewählt. Haben sie evtl. den falschen Link angeklickt?</div>
            </Container>;
        }

        if (this.state.error !== null) {
            return  <Container fluid={true} className={"page-container error-no-cid-container p-0"}>
                <div className={"error-message"}>{this.state.error}</div>
            </Container>;
        }

        return <Container fluid={true} className={"page-container p-0"}>
            {this.questionController.isFinished() && this.state.editMode == false && <FinishedHeader />}
            {this.state.editMode && <EditModeHeader qctrl={this.questionController}  />}
                    <ProjectNavigation qctrl={this.questionController} name={this.state.name} onResultsPageClicked={(e) => this.setState({showResults: true})} categories={this.state.categories}></ProjectNavigation>
                    {!this.state.showResults && <MainNavigation qctrl={this.questionController} categories={this.state.categories}></MainNavigation>}
                    {!this.state.showResults && <SubNavigation qctrl={this.questionController} categories={this.state.categories}></SubNavigation>}
                    {this.state.showResults && <ResultsPage qctrl={this.questionController} onCloseResults={(e) => this.setState({showResults: false})}></ResultsPage>}
                    {!this.state.showResults && <FormularSite onResultsPageClicked={(e) => this.setState({showResults: true})} qctrl={this.questionController} categories={this.state.categories}></FormularSite>}
                    <div className={"webconfig-type-info-shield"}>{WEBCONFIG_TRADE_MODE}</div>
               </Container>;
    }
};

FormularPage.propTypes = {
    title: PropTypes.string,
};


export default FormularPage;
