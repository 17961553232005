import FormularConfigurationService from "../services/FormularConfigurationService";

const Handlebars = require("handlebars");

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

export default class QuestionController {

    constructor(formularClass) {
        this.formular = formularClass;

    }

    hasSubcategories(category) {
        return category.subcategories.length > 0;
    }

    updateWebsiteTemplates() {

        this.formular.state.website_templates = {};

        if (typeof this.formular.state.answers !== "undefined" && this.formular.state.answers != null) {
            for (const a of Array.from(Object.values(this.formular.state.answers))) {

                const option = this.getOptionByName(a.value);

                if (typeof option !== "undefined" && option != null) {
                    try {
                        if (Array.isArray(option)) {
                            for (const o of option) {
                                if (typeof o["Web-Configurator-ID"] !== "undefined") {
                                    const mo = JSON.parse(JSON.stringify(a));
                                    mo["Web-Configurator-ID"] = o["Web-Configurator-ID"];
                                    this.formular.state.website_templates[o["id"]] = mo;
                                }
                            }
                        } else {
                            if (typeof option["Web-Configurator-ID"] !== "undefined") {
                                this.formular.state.website_templates[option["id"]] = a;
                            }
                        }

                    } catch (e) {
                        console.error(e);
                    }
                }
            }
            this.formular.setState({website_templates: this.formular.state.website_templates});
        }

    }

    updateQuestionVisibility() {
        const current_conditional_map = this.getAnswersMapWithID();
        this.formular.state.current_conditional_map = current_conditional_map;
        this.formular.setState({
            current_conditional_map: current_conditional_map
        });

        this.updateWebsiteTemplates();

        const elements = this.getAllFlatQuestionsArray();
        const current_elements_flat = elements.filter((sc) => {
                let found = false;
                if (!this.isVisible(sc)) {
                    found = false;
                }
                else {
                    if (this.isCategoryMode()) {
                        if (typeof sc.question_category !== "undefined" && sc.question_category == this.getCurrentCategoryMode()) {
                            return true;
                        }
                        return false;
                    }
                    return true;
                }
                return found;
          })
          .sort(QuestionController.sortQuestionsReverse);

        let current_elements_hierarchical = FormularConfigurationService.buildHierarchicalQuestions(current_elements_flat);

        this.formular.state.current_elements_flat = current_elements_flat;
        this.formular.state.current_elements_hierarchical = current_elements_hierarchical;
        this.formular.setState({
            current_elements_flat: current_elements_flat,
            current_elements_hierarchical: current_elements_hierarchical
        });
    }

    getAllCategories() {
        return this.formular.state.current_elements_hierarchical;
    }

    isEditMode() {
      return this.formular.state.editMode;
    }

    getZohoCustomerData() {
        return this.formular.state.zohoCustomerData;
    }

    getSummaryConfig() {
        return this.formular.state.summaryConfig;
    }

    isOnlyTestFinished() {
        return this.formular.state.summaryConfig &&
               this.formular.state.summaryConfig.orderInfos &&
               this.formular.state.summaryConfig.orderInfos.orderStatus &&
               this.formular.state.summaryConfig.orderInfos.orderStatus == "test";
    }

    isFinished() {
        if (this.isCategoryMode()) {
            return false;
        }
        return this.formular.state.finished === true;
    }

    answerValues() {
        const amap = {};

        for (let k of Object.keys(this.formular.state.answers)) {
                amap[k] = this.formular.state.answers[k].value;
        }

        return amap;
    }

    answers() {
        return this.formular.state.answers;
    }

    getCommentsForQuestion(question) {
        if (question != null) {
            this.fillOrCheckAnswerField(question.id);
            return this.formular.state.answers[question.id].comment;
        }
        return null;
    }

    fillOrCheckAnswerField(id) {
        if (typeof this.formular.state.answers[id] === "undefined") {
            this.formular.state.answers[id] = {
                value: null,
                comment: null,
                todos: [],
                uploads: [],
                additionalValue: null
            };
        }
        if (typeof this.formular.state.answers[id].value === "undefined") {
            this.formular.state.answers[id] = {
                value: this.formular.state.answers[id],
                comment: null,
                todos: [],
                uploads: [],
                additionalValue: null
            };
        }
        if (typeof this.formular.state.answers[id].uploads === "undefined") {
            this.formular.state.answers[id].uploads = [];
        }

        if (typeof this.formular.state.answers[id].additionalValue === "undefined") {
            this.formular.state.answers[id].additionalValue = null;
        }
    }

    getUploadsForQuestion(question) {
        if (question != null) {
            this.fillOrCheckAnswerField(question.id);
            return (typeof this.formular.state.answers[question.id].uploads !== "undefined") ? this.formular.state.answers[question.id].uploads : [];
        }
        return [];
    }

    getCraftsmenTodosDetails(id){
        return this.formular.state.craftsmenTodos[id];
    }

    getProductsDetails(id){
        return this.formular.state.products[id];
    }

    getAgenturTodosDetails(id){
        return this.formular.state.agenturTodos[id];
    }

    removeFileFromQuestion(question, file) {
        const id = question.id;
        this.fillOrCheckAnswerField(id);
        this.formular.state.answers[id].uploads = this.formular.state.answers[id].uploads.filter((f) => f.id != file.id);

        this.formular.setState({
            answers: this.formular.state.answers
        });

        this.formular.onFormularAnswerChangedSuccfessfully(this.formular.state.answers).then(() => {

        });
    }

    appendUploadToQuestion(question, file) {
        const id = question.id;
        this.fillOrCheckAnswerField(id);
        this.formular.state.answers[id].uploads.push({
            name: file.filename,
            type: file.type,
            id: file.id
        });
        this.formular.setState({
            answers: this.formular.state.answers
        });

        this.formular.onFormularAnswerChangedSuccfessfully(this.formular.state.answers).then(() => {

        });
    }

    findTodosForQuestionAndValue(id, val) {
        const options = [];
        try {
            let vals = [val];
            if (Array.isArray(val)) {
                vals = val;
            }
            for (let v of vals) {
                const option = this.getOptionByName(v);
                if (option != null) {
                    if (typeof option["Handwerker TODO"] !== "undefined" && option["Handwerker TODO"].length > 0) {
                        // There are some toods
                        options.push(...option["Handwerker TODO"]);
                    }
                }
            }
        }
        catch (e) {}
        return options;
    }

    findProductsForQuestionAndValue(id, val) {
        const options = [];
        try {
            let vals = [val];
            if (Array.isArray(val)) {
                vals = val;
            }
            for (let v of vals) {
                const option = this.getOptionByName(v);
                if (option != null) {
                    if (typeof option["Verknüpfte Produkte"] !== "undefined" && option["Verknüpfte Produkte"].length > 0) {
                        // There are some toods
                        options.push(...option["Verknüpfte Produkte"]);
                    }
                }
            }


        }
        catch (e) {}
        return options;
    }


    findAgenturTodosForQuestionAndValue(id, val) {
        const options = [];
        try {
            let vals = [val];
            if (Array.isArray(val)) {
                vals = val;
            }
            for (let v of vals) {
                const option = this.getOptionByName(v);
                if (option != null) {
                    if (typeof option["Agentur TODO"] !== "undefined" && option["Agentur TODO"].length > 0) {
                        // There are some toods
                        options.push(...option["Agentur TODO"]);
                    }
                }
            }


        }
        catch (e) {}
        return options;
    }

    replaceTemplatePlaceholders(template_string, question) {
        const template = Handlebars.compile((template_string) ? template_string : "");
        const questionDict = {};

        questionDict["answers"] = this.answerValues();
        questionDict["question"] = this.getZohoCustomerData();
        questionDict["zoho"] = this.getZohoCustomerData();
        return template(questionDict);
    }

    getAdditionalData(question) {

        const id = question.id;
        if (typeof this.formular.state.answers[id].additionalValue !== "undefined" && this.formular.state.answers[id].additionalValue != null) {
            return this.formular.state.answers[id].additionalValue;
        }
        return null;
    }

    hasAdditionalData(id) {

        if (typeof this.formular.state.answers[id].additionalValue !== "undefined" && this.formular.state.answers[id].additionalValue != null) {
            return true;
        }
        return false;
    }

    appendAdditionalValue(question, value) {
        const id = question.id;
        this.fillOrCheckAnswerField(question.id);
        this.formular.state.answers[id].additionalValue = value;
        this.formular.setState({
            answers: this.formular.state.answers
        });

        const q = this.findQuestionById(id);
        this.checkForErrorRemoval(q);

        this.formular.onFormularAnswerChangedSuccfessfully(this.formular.state.answers).then(() => {

        });


    }

    updateAnswers(id, val, recalculate=true) {

        this.fillOrCheckAnswerField(id);
        const q = this.findQuestionById(id);
        let additionalValue  = null;
        if (this.hasAdditionalData(id)) {
            if (!confirm("Es gibt bereits eine Änderung für diese Antwort, wenn sie jetzt die Auswahl ändern werden auch die Änderungen wie z.B. der Text zurückgesetzt. Wollen sie fortfahren?")) {
                return;
            }
            const option = this.getOptionByName(val);
            additionalValue = option["Beispiel-Text"];
        }
        if (additionalValue == null) {
            const option = this.getOptionByName(val);
            if (typeof option !== "undefined" && option != null) {
                if (typeof option["Beispiel-Text"] !== "undefined") {
                    additionalValue = option["Beispiel-Text"];
                }
            }
        }
        const option = this.getOptionByName(val);

       // this.lastChanged = new Date().getTime();

        this.formular.state.answers[id].additionalValue = additionalValue;
        this.formular.state.answers[id].value = val;
        this.formular.state.answers[id].todos = this.findTodosForQuestionAndValue(id, val);
        this.formular.state.answers[id].agentur_todos = this.findAgenturTodosForQuestionAndValue(id, val);
        this.formular.state.answers[id].products = this.findProductsForQuestionAndValue(id, val);
        this.formular.setState({
            answers: this.formular.state.answers
        });

        this.checkForErrorRemoval(q);
        if (recalculate) {
            this.updateQuestionVisibility();

            this.formular.onFormularAnswerChangedSuccfessfully(this.formular.state.answers).then(() => {

            });
        }

    }

    removeValueForQuestion(question, val) {
        const id = question.id;
        this.fillOrCheckAnswerField(id);
        let oldValue = this.formular.state.answers[id].value;
        if (typeof oldValue === "undefined" || oldValue == null || !Array.isArray(oldValue)) {
            oldValue = [];
        }
        const index = oldValue.findIndex((d) => d === val);
        if (index >= 0) {
            oldValue.splice(index, 1);
        }
        this.updateAnswers(id, oldValue);
    }

    addValueForQuestion(question, val) {
        const id = question.id;
        this.fillOrCheckAnswerField(id);
        let oldValue = this.formular.state.answers[id].value;
        if (typeof oldValue === "undefined" || oldValue == null || !Array.isArray(oldValue)) {
            oldValue = [];
        }
        oldValue.push(val);
        this.updateAnswers(id, oldValue);
    }

    setComment(question, comment) {
        const id = question.id;
        this.fillOrCheckAnswerField(id);
        this.formular.state.answers[id].comment = comment;
        this.formular.setState({
            answers: this.formular.state.answers
        });

        this.formular.onFormularAnswerChangedSuccfessfully(this.formular.state.answers).then(() => {

        });
    }

    setValueForQuestion(question, val, recalculate=true) {
        this.updateAnswers(question.id, val, recalculate);
    }

    isQuestionAnswered(question) {
        if (this.getValueForQuestion(question) != null) {
            return true;
        }
        return false;
    }

    replaceVariables(link) {

        const answers = this.answerValues();
        const amap = this.getAnswersMapWithID();

        for (const a of amap) {
            try {
                if (answers[a.id] != null) {
                    link = link.replace("{$"+a.conditionalId+"}", answers[a.id]);
                }
            }
            catch (e) {}
        }

        return link;
    }

    getValueForQuestion(question) {
        this.fillOrCheckAnswerField(question.id);
        return this.formular.state.answers[question.id].value;
    }

    getOptionByName(name){
        if (typeof name === "undefined" || name == null) {
            return null;
        }
        if (Array.isArray(name)) {
            const list = [];
            for (let o of name) {
                list.push(this.getOptionByName(o));
            }
            return list;
        }
        return (this.formular.state.options[name.toLowerCase()]) ? this.formular.state.options[name.toLowerCase()] : null;
    }

    getCurrentSubcategories() {
        const category = this.getCurrentCategory();
        return this.getAllVisibleSubcategories(category);
    }

    getCurrentCategory() {
        let index = this.getAllCategories().findIndex((c) => this.getCurrentQuestion().Category == c.id);
        if (typeof index === "undefined" || index == null || index < 0) {
            index = 0;
        }
        return this.getAllCategories()[index];
    }

    getCurrentSubCategory() {
        const currentCat = this.getCurrentCategory();
        const index = currentCat.subcategories.findIndex((c) => this.getCurrentQuestion().Subcategory == c.id);
        return currentCat.subcategories[index];
    }

    getCurrentQuestion() {
        return this.formular.state.currentQuestion;
    }

    static sortQuestionsReverse(a, b) {
        const a_id = ((parseInt(a.Category)*100)+(parseInt(a.Subcategory)*10)+parseInt(a.Index));
        const b_id = ((parseInt(b.Category)*100)+(parseInt(b.Subcategory)*10)+parseInt(b.Index));
        if (a_id < b_id) {
            return -1;
        }
        if (b_id < a_id) {
            return 1;
        }
        return 0;
    }

    static sortQuestions(a, b) {
        let index = (typeof a.Index !== "undefined") ? parseInt(a.Index) : 0;
        const a_id = ((parseInt(a.Category)*100)+(parseInt(a.Subcategory)*10)+index);
        index = (typeof b.Index !== "undefined") ? parseInt(b.Index) : 0;
        const b_id = ((parseInt(b.Category)*100)+(parseInt(b.Subcategory)*10)+index);
        if (a_id < b_id) {
            return 1;
        }
        if (b_id < a_id) {
            return -1;
        }
        return 0;
    }

    getAllFlatQuestionsArraySorted() {
        return this.formular.state.flatQuestionsSorted;
    }

    getAllFlatQuestionsArray() {
        return Array.from(Object.values(this.formular.state.flatQuestions));
    }

    getAnswersMapWithID() {
        const list = [];
        let last_subcategory = this.getAllFlatQuestionsArray()[0].Subcategory;
        let sc_index = 1;
        for (let e of this.getAllFlatQuestionsArray()) {
            if (last_subcategory != e.Subcategory) {
                sc_index = 1;
            }
            if (this.isCategoryMode()) {
                if (typeof e.question_category !== "undefined" && e.question_category != this.getCurrentCategoryMode()) {
                    continue;
                }
            }
            if (typeof e.Index === "undefined" || e.Index == null || e.Index == "") {
                e.Index = sc_index;
            }
            else {
                try {
                    e.Index = parseInt(e.Index);
                }
                catch(e) {}
            }
            if (typeof e.Category !== "undefined" && typeof e.Subcategory !== "undefined") {
                e.conditionalId = e.Category.trim() + "." + e.Subcategory.trim();
                list.push(e);
            }
            if (typeof e.Category !== "undefined" && typeof e.Subcategory !== "undefined") {
                e.conditionalId = e.Category.trim() + "." + e.Subcategory.trim() + "." + e.Index;
                list.push(e);
            }
            sc_index++;
            last_subcategory = e.Subcategory;
        }
        return list;
    }

    replaceConditionalsById(condition) {

        let newcondition = condition.toString();
        const amap = this.getAllFlatQuestionsArraySorted();
        if (this.isDevMode()) {
            newcondition = newcondition.replace("0.0.0", '["devmode"]');
        }
        else {
            newcondition = newcondition.replace("0.0.0", "[]");
        }
        // Wir müssen die Liste sortieren nach der conditionalID weil sonst 1.1.1 von 11.1.1 ersetzt wird und
        // dann bleibt die 1 vorne stehen und die condition klappt nicht.

        for (const a of amap) {
            try {
                newcondition = newcondition.replaceAll(a.conditionalId, "answers['"+a.id+"']");
            }
            catch (e) {}
        }

        return newcondition;
    }

    isVisible(c) {
        if (typeof c.elements !== "undefined" && c.elements.length > 0)  {
            c = c.elements[0];
        }
        if (this.isCategoryMode()) {
            const cat = this.getCurrentCategoryMode();
            if (typeof c.question_category !== "undefined" && c.question_category != cat) {
                return false;
            }
        }
        return this.checkCondition(c);
    }

    isMainCategoryFinished(c) {
        const subcategories = this.getAllVisibleSubcategories(c);
        if (typeof subcategories !== "undefined") {
            // check for main category
            for (const sc of subcategories) {
                if (!this.hasAnswerForQuestionOrPage(sc)) {
                    return false;
                }
            }
            return true;
        }

        return false;
    }

    isClickable(c) {
        if (this.isCategoryMode()) return true;
        if (typeof c.subcategories !== "undefined") {
            // check for main category
            const subcat = c.subcategories[0];
            if (this.isQuestionAnswered(subcat.elements[0]) === false) {
                return false;
            }
            return true;
        }
        else if (typeof c.elements !== "undefined") {
            // check for sub category
            if (this.isQuestionAnswered(c.elements[0]) === false) {
                return false;
            }
            return true;
        }
        else {
            // check for question itself
            if (this.isQuestionAnswered(c) === false) {
                return false;
            }
            return true;
        }
        return false;
    }

    isDevMode() {
        return this.formular.state.devMode == true;
    }

    parseCondition(condition) {
        try {
            // at first we replace the conditional questionmarkers
            // for example "1.1" or anything like this
            const newCondition = this.replaceConditionalsById(condition);

            // remove unreplaced with "true"
            const splitted = newCondition.split(/&&|\|\|/);
            for (const s of splitted) {
                if (!s.includes("answers[")) {
                    newCondition.replace(s, "true");
                }
            }


            const answers = this.answerValues();
            answers["active"] = true;

            // Maybe there is a problem and not all answers are filled (in category mode)
            // thats why we are checking allq uestions and fill them empty
            try {
                const all_questions = this.getAllFlatQuestionsArraySorted();
                for (const e of all_questions) {
                    if (typeof answers[e.id] === "undefined" || answers[e.id] == null) {
                        answers[e.id] = [];
                    }
                }
            }
            catch(er) {
                console.error("Error in generating answers", er);
            }

            try {
                /*
                const cleared_json = JSON.stringify(answers)
                  .replaceAll("\n", "")
                  .replaceAll("\r", "")
                  .replaceAll("\\", "");
                let answerMapString = "var answers = JSON.parse('"+cleared_json+"'); ";*/

                //  const F = new Function (answerMapString+" return ("+newCondition+");");
                // if (F()) {
                if (eval(newCondition)) {
                    return true;
                }
                else {
                    return false;
                }
            }
            catch (e) {
                console.error("Error in bedingung: "+condition, newCondition);
            }
        }
        catch(e) {

            console.error("Error in check for bedingung: "+condition, e);
        }

        return false;
    }

    checkCondition(q) {
        try {

            if (typeof q.Bedingung === "undefined" ||
              q.Bedingung === null ||
              q.Bedingung.trim() === "") {
                return true;
            }
            else {
                return this.parseCondition(q.Bedingung);
            }
        }
        catch (e) {

        }
        return false;
    }

    getCurrentQuestionsForPage() {
        const ele = this.formular.state.currentQuestion;
        for (let c of this.getAllCategories()) {
            for (let sc of c.subcategories) {
                for (let e of sc.elements) {
                    if (e.id === ele.id) {
                        return sc.elements;
                    }
                }
            }
        }

        return [];
    }

    getAllResults() {
        const ansers = [];
        const craftsmenTodos = [];
        const agenturTodos = [];
        const products = [];
        if (typeof this.formular.state.answers !== "undefined" && this.formular.state.answers != null) {
            for (const key of Object.keys(this.formular.state.answers)) {
                const answer = this.formular.state.answers[key];
                const q = this.findQuestionById(key);
                if (q != null) {
                    const o ={
                        name: q["Bezeichner"],
                        id: q["conditionalId"],
                        key: key,
                        value: answer.value,
                        comment: answer.comment,

                        todos: answer.todos,
                        products: answer.products,
                        agentur_todos: answer.agentur_todos,
                        uploads: answer.uploads,
                        additionalValue: answer.additionalValue
                    };
                    if (typeof answer["Web-Configurator-ID"] !== "undefined") {
                        o["Web-Configurator-ID"] = answer["Web-Configurator-ID"];
                    }

                    ansers.push(o);
                    if (answer.todos) {
                        for (const t of answer.todos) {
                            const todo = this.getCraftsmenTodosDetails(t);
                            craftsmenTodos.push(
                              todo
                            );
                        }

                    }
                    if (answer.agentur_todos) {
                        for (const t of answer.agentur_todos) {
                            const todo = this.getAgenturTodosDetails(t);
                            agenturTodos.push(
                              todo
                            );
                        }

                    }
                    if (answer.products) {
                        for (const t of answer.products) {
                            const todo = this.getProductsDetails(t);
                            products.push(
                              todo
                            );
                        }

                    }
                }
            }
        }


        return {
            products: products,
            agenturTodos: agenturTodos,
            craftsmenTodos: craftsmenTodos,
            answers: ansers
        };
    }

    getSelectedProducts() {
        const results = this.getAllResults();
        return results.products;
    }

    findFirstQuestionByCategory(cat) {
        return this.formular.state.current_elements_flat[0];
    }

    findQuestionById(id) {
        for (let c of this.getAllCategories()) {
            for (let sc of c.subcategories) {
                for (let e of sc.elements) {
                    if (e.id === id) {
                        return e;
                    }
                }
            }
        }
        return null;
    }

    getCurrentHashParameter(hashParameterName) {
        let theURL              = new URL(window.location.origin);             // create dummy url
        theURL.search           = window.location.hash.substring(1);

        return theURL.searchParams.get(hashParameterName);
    }

    getCurrentCategoryModeUpperCase() {
        return this.getCurrentCategoryMode().capitalize();
    }

    getCurrentCategoryMode() {
        return this.formular.state.currentCategory;
    }

    getWebconfiguratorTemplates() {
        return this.formular.state.website_templates;
    }

    isCategoryMode() {
        return this.formular.state.currentCategory != null;
    }

    getOptions(option_list) {
        const list = [];
        const option_list_global = Object.values(this.formular.state.options);
        for (let o of option_list) {
            const index = option_list_global.findIndex((d) => d.id.toLowerCase() === o.toLowerCase());
            if (index >= 0)  {
                list.push(option_list_global[index]);
            }
        }
        return list;
    }

    setOrUpdateHashParameter( hashParameterName, hashParameterValue ) {
        let theURL              = new URL(window.location.origin );             // create dummy url
        theURL.search           = window.location.hash.substring(1);        // copy current hash-parameters without the '#' AS search-parameters
        theURL.searchParams.set( hashParameterName, hashParameterValue );   // set or update value with the searchParams-API
        window.location.hash    = theURL.searchParams;                      // Write back as hashparameters
    }

    getFirstQuestion() {
        const e = this.getAllQuestionElementsFlat();
        return e[0];
    }

    isCurrentLastQuestion() {
        const q = this.getCurrentQuestion();
        if (this.findNextQuestionPage(q) == null) {
            return true;
        }
        return false;
    }

    hasAnsweredAllQuestions() {

        let lastQuestion = false;
        let currentQuestion = this.getFirstQuestion();
        while (!lastQuestion) {
            const error = this.checkQuestionForAnswerAndGenerateError(currentQuestion);
            if (error) {
                return false;
            }
            currentQuestion = this.findNextQuestionPage(currentQuestion);
            if (currentQuestion == null) {
                lastQuestion = true;
            }
        }

        return true;
    }


    findPrevQuestionPage(question) {
        const currentq = (typeof question !== "undefined") ? question : this.getCurrentQuestion();

        const currentSubcatIndexNumber = ((parseInt(currentq.Category)*10) + parseInt(currentq.Subcategory));
        const listOfAllElementsFlat = this.getAllQuestionElementsFlat();
        let selectedQuestion = null;
        for (let i=listOfAllElementsFlat.length-1; i>=0; i--) {
            const indexNUmber = ((parseInt(listOfAllElementsFlat[i].Category)*10) + parseInt(listOfAllElementsFlat[i].Subcategory));
            if (indexNUmber < currentSubcatIndexNumber) {
                if (i >= 0) {
                    selectedQuestion = listOfAllElementsFlat[i];
                    break;
                }
            }
        }

        return selectedQuestion;
    }

    getAllVisibleSubcategories(category) {
        const categories = category.subcategories;
        return categories;
    }

    getAllVisibleElementsOfSubcategory(category) {
        const categories = category.elements;
        return categories;
    }

    getAllQuestionElementsFlatWithoutCategory() {
        const elements = [];
        const cats = this.formular.state.categories;
        for (const c of cats) {
            const subcats = c.subcategories;
            for (const subcat of subcats) {
                const subcatElements = subcat.elements;
                elements.push(...subcatElements);
            }
        }
        return elements;
    }

    getAllQuestionElementsFlat() {
        return this.formular.state.current_elements_flat;
    }


    findNextVisibleQuestionPageFromQuestion(currentq) {

        const currentSubcatIndexNumber = ((parseInt(currentq.Category)*10)+parseInt(currentq.Subcategory));
        const listOfAllElementsFlat = this.getAllQuestionElementsFlat(currentq);
        let selectedQuestion = null;
        for (let i=0; i<listOfAllElementsFlat.length; i++) {
            const indexNUmber = ((parseInt(listOfAllElementsFlat[i].Category)*10)+parseInt(listOfAllElementsFlat[i].Subcategory));
            if (indexNUmber >= currentSubcatIndexNumber) {
                if (i < listOfAllElementsFlat.length) {
                    selectedQuestion = listOfAllElementsFlat[i];
                    break;
                }
            }
        }

        return selectedQuestion;
    }

    findNextQuestionPage(question) {
        const currentq = (typeof question !== "undefined") ? question : this.getCurrentQuestion();

        const currentSubcatIndexNumber = ((parseInt(currentq.Category)*10) + parseInt(currentq.Subcategory));
        const listOfAllElementsFlat = this.getAllQuestionElementsFlat();
        let selectedQuestion = null;
        for (let i=0; i<listOfAllElementsFlat.length; i++) {
            const indexNUmber = ((parseInt(listOfAllElementsFlat[i].Category)*10) + parseInt(listOfAllElementsFlat[i].Subcategory));
            if (indexNUmber > currentSubcatIndexNumber) {
                if (i < listOfAllElementsFlat.length) {
                    selectedQuestion = listOfAllElementsFlat[i];
                    break;
                }
            }
        }

        return selectedQuestion;
    }

    setCurrentMainCategory(p) {
        this.setCurrentQuestion(this.findNextVisibleQuestionPageFromQuestion(p.subcategories[0].elements[0]));
    }

    setCurrentQuestionPage(p) {
        this.setCurrentQuestion(p.elements[0]);
    }

    setCurrentQuestionFromOutside(q) {
        if (this.isQuestionAnswered(q)) {
            this.setCurrentQuestion(q);
        }
    }

    setCurrentQuestion(q){


        this.setOrUpdateHashParameter("question", q.id);
        this.formular.setState( {
            currentQuestion: q
        });
    }

    nextQuestionPage() {

        const next = this.findNextQuestionPage();
        if (next != null) {
            this.setCurrentQuestion(next);
        }

    }

    setErrors(errors) {
        this.formular.setState({errors: errors});
    }

    isQuestionOptional(question) {
        return typeof question["Optional"] !== "undefined" && question["Optional"] != null &&
            question["Optional"] == true;
    }

    hasAnswerForQuestionOrPage(q_or_c) {
        if (typeof q_or_c.elements !== "undefined") {
            const elements = this.getAllVisibleElementsOfSubcategory(q_or_c);
            for (let q of elements) {
                if (this.checkQuestionForAnswerAndGenerateError(q) != null) {
                    return false;
                }
            }
            return true;
        }
        else {
            return this.checkQuestionForAnswerAndGenerateError(q_or_c) == null;
        }
    }

    checkQuestionForAnswerAndGenerateError(question) {
        const answers = this.answers();
        if (this.checkCondition(question)) {
            if (!this.isQuestionOptional(question)) {
                if (typeof answers[question.id] === "undefined" ||
                  answers[question.id] == null) {
                    return {missing: true, text: (question.Error) ? question.Error : "Bitte fülle dieses Feld aus um fortzufahren."};
                }
                if (answers[question.id].value == null) {
                    return {missing: true, text: (question.Error) ? question.Error : "Bitte fülle dieses Feld aus um fortzufahren."};
                }
                if (typeof answers[question.id].value.trim !== "undefined" &&
                  answers[question.id].value.trim() == "") {
                    return {missing: true, text: (question.Error) ? question.Error : "Bitte fülle dieses Feld aus um fortzufahren."};
                }
                if (Array.isArray(answers[question.id].value) &&
                  answers[question.id].value.length <= 0) {
                    return {missing: true, text: (question.Error) ? question.Error : "Bitte fülle dieses Feld aus um fortzufahren."};
                }
            }

        }
        return null;
    }

    getId() {
        return this.formular.state.cid;
    }

    getError(question) {
        if (typeof this.formular.state.errors[question.id] !== "undefined") {
            return this.formular.state.errors[question.id];
        }
        return null;
    }

    checkForErrorRemoval(q) {
        if (typeof this.formular.state.errors[q.id] !== "undefined") {
            if (this.checkQuestionForAnswerAndGenerateError(q) == null) {
                delete this.formular.state.errors[q.id];
                this.setErrors(this.formular.state.errors);
            }
        }
    }

    checkForAnswersBeforeStep() {
        const errors = {};

        const currentQuestions = this.getCurrentQuestionsForPage();

        for (let i=0; i<currentQuestions.length; i++) {
            const q = currentQuestions[i];
            const error = this.checkQuestionForAnswerAndGenerateError(q);
            if (error) {
                errors[q.id] = error;
            }
        }

        if (Object.keys(errors).length > 0) {
            this.setErrors(errors);
            return errors;
        }
        this.setErrors({});
        return null;
    }

    stepBack() {

        const next = this.findPrevQuestionPage();
        if (next != null) {
            this.setCurrentQuestion(next);
        }
    }

}
