import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import QuestionType from "./QuestionType";
import Input from "reactstrap/lib/Input";
import FormGroup from "reactstrap/lib/FormGroup";
import { FaCheck } from 'react-icons/fa';

class QuestionRichText extends QuestionType {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    getTextValue() {
        let txt = this.getValue();
        if (txt == null && this.hasVorauswahl()) {
            txt = this.getVorauswahl();
        }
        return txt;
    }


    render() {
        return <Container fluid={true} className={"rich-text-container "+(this.hasError() ? "error" : "")}>
            <Row>
                <Col className={"p-1"} md={12}>
                    {this.isVorauswahl() && <FaCheck className={"vorauswahl_check"} onClick={(e) => this.onVorauswahlCheckClicked(e)}/>}
                    <Input disabled={this.isFinished()} rows={6} type={"textarea"} value={this.getTextValue()}
                           placeholder={this.placeholder()}
                           onBlur={(e) => this.setValue(e.target.value)}
                           onChange={(e) => this.setValueQuick(e.target.value)}
                           name={"text"} />
                    {this.renderErrors()}
                </Col>
            </Row>
        </Container>;
    }
};

QuestionRichText.propTypes = {

};


export default QuestionRichText;
