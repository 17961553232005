import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import CommunicationComponent from "../communication/CommunicationComponent";
import { FaCheckCircle } from "react-icons/fa";

class MainNavigation extends CommunicationComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    qCtrl() {
        return this.props.qctrl;
    }

    clickOnMainCategory(e, c) {
        this.fireEvent("pageChanged");
        this.qCtrl().setCurrentMainCategory(c);
    }

    renderCategories() {
        const _this = this;
        const list = this.qCtrl().getAllCategories()
          .filter((c) => this.qCtrl().hasSubcategories(c)).map((c) => {
            const clickable = this.qCtrl().isClickable(c);
            const finished = this.qCtrl().isMainCategoryFinished(c);
            const checkMark = (finished) ? <FaCheckCircle size={14} className={"checkCircleMain"} /> : null;
            const clickableClass = ((checkMark || this.qCtrl().isCategoryMode()) ? "clickable" : "");

            return <div  className={"eq-col "+((this.qCtrl().getCurrentCategory().id == c.id) ? "active" : "")} >
                    <div onClick={(e) => (clickable) ? this.clickOnMainCategory(e, c) : null}  className={"eq-circle-box "+" "+clickableClass}><div>{c.id}{finished ? checkMark : null}</div></div>
                    <span onClick={(e) => (clickable) ? this.clickOnMainCategory(e, c) : null} className={clickableClass}>{c.name}</span>
                </div>;
        });
        return list;
    }

    render() {
        return <Container fluid={true} className={"p-2 mainnav"}>
            <Row className={"eq-box"}>
                {this.renderCategories()}
            </Row>
        </Container>;
    }
};

MainNavigation.propTypes = {

};


export default MainNavigation;
