import axios from 'axios';
import { WEBCONFIG_HOME_URL, WEBCONFIG_TRADE_MODE, ZOHO_API, ZOHO_EDIT_API, ZOHO_API_SAVE_CATEGORY, ZOHO_EDIT_API_CATEGORY } from "../config";

export default class ZohoFlowService {
  constructor() {}

  sendEditModeUpdateDataToZoho(questionController) {
    return new Promise((resolve, reject) => {
      try {
        const id = questionController.getId();
        let posturl = ZOHO_EDIT_API + id;
        if (questionController.isCategoryMode()) {
          posturl = ZOHO_EDIT_API_CATEGORY + id;
        }
        const summaryConfig = questionController.getSummaryConfig();
        const orderInfos = (summaryConfig && summaryConfig.orderInfos) ? summaryConfig.orderInfos : {

        };
        orderInfos.categoryMode = questionController.getCurrentCategoryMode();

        return this.sendDataToZoho(
          questionController,
          orderInfos,
          posturl
        ).then(() => {
          resolve();
        });
      } catch (e) {
        return reject(e);
      }
    });
  }

  sendDataToZohoOnlyCategory(questionController, orderInfos, posturl) {
    return new Promise((resolve, reject) => {
      try {
        const id = questionController.getId();
        const answers = questionController.getAllResults();
        const answerList = {};
        for (const a of answers.answers) {
          answerList[a.key] = a;
        }
        if (typeof posturl === 'undefined') {
          posturl = ZOHO_API_SAVE_CATEGORY + id;
        }

        const data = {
          answers: answerList,
          webconfigurator_templates: orderInfos ? orderInfos.webconfigurator_templates : null,
          craftsmenTodos: answers.craftsmenTodos,
          agenturTodos: answers.agenturTodos,
          products: answers.products,
          categoryMode: orderInfos ? orderInfos.categoryMode : null,
          orderInfos: orderInfos,
          Unternehmensgruppe: WEBCONFIG_TRADE_MODE,
          contract_order: "https://universal.dasbad3.de/universalbackend/public/api/craftsmenconfiguration/ordercontract/"+id,
          contract_av: "https://universal.dasbad3.de/universalbackend/public/api/craftsmenconfiguration/avcontract/"+id,
          cancellation_url: WEBCONFIG_HOME_URL + "widerruf/" + id,
          resultsPage:
            WEBCONFIG_HOME_URL + '#cid=' + id + '&page=answers',
          resultsPDF:
            'https://universal.dasbad3.de/universalbackend/public/api/craftsmenconfiguration/summary/' +
            id,
        };
        return axios
          .post(posturl, data, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
           // window.location.reload();

            return resolve();
          })
          .catch((err) => {
            return reject(err);
          });
      } catch (e) {
        return reject(e);
      }
    });
  }

  sendDataToZoho(questionController, orderInfos, posturl) {
    return new Promise((resolve, reject) => {
      try {
        const id = questionController.getId();
        const answers = questionController.getAllResults();
        const answerList = {};
        for (const a of answers.answers) {
          answerList[a.key] = a;
        }
        if (typeof posturl === 'undefined') {
          posturl = ZOHO_API + id;
        }

        const data = {
          answers: answerList,
          craftsmenTodos: answers.craftsmenTodos,
          webconfigurator_templates: (orderInfos) ? orderInfos.webconfigurator_templates : null,
          agenturTodos: answers.agenturTodos,
          products: answers.products,
          orderInfos: orderInfos,
          Unternehmensgruppe: WEBCONFIG_TRADE_MODE,
          contract_order: "https://universal.dasbad3.de/universalbackend/public/api/craftsmenconfiguration/ordercontract/"+id,
          contract_av: "https://universal.dasbad3.de/universalbackend/public/api/craftsmenconfiguration/avcontract/"+id,
          cancellation_url: WEBCONFIG_HOME_URL + "widerruf/" + id,
          resultsPage:
            WEBCONFIG_HOME_URL + '#cid=' + id + '&page=answers',
          resultsPDF:
            'https://universal.dasbad3.de/universalbackend/public/api/craftsmenconfiguration/summary/' +
            id,
        };
        return axios
          .post(posturl, data, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            window.location.reload();

            return resolve();
          })
          .catch((err) => {
            return reject(err);
          });
      } catch (e) {
        return reject(e);
      }
    });
  }
}
