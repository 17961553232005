import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import FormGroup from "reactstrap/lib/FormGroup";
import Input from "reactstrap/lib/Input";
import Label from "reactstrap/lib/Label";
import QuestionType from "./QuestionType";

class QuestionYesNo extends QuestionType {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    getValue() {
        let v = super.getValue();
        if (typeof v === "undefined" || v == null) {
            if (this.hasVorauswahl()) {
                v = this.getVorauswahl();
                this.setValue(v);
            }
        }
        return v;
    }



    onChangeOption(e, val) {
        this.setValue(val);
        return e;
    }

    isChecked(val) {
        return this.getValue() === val;
    }

    render() {
        return <Container>
            <Row>
                <Col md={12}>
                    <FormGroup className={"p-3 yesno-container "+(this.hasError() ? "error" : "")}>
                        <Row>
                            <Col md={12} className={"p-2 option"}>
                                <Label check>
                                    <Input disabled={this.isFinished()} type={"radio"} checked={this.isChecked("yes")} onChange={(e) => this.onChangeOption(e, "yes")} name={"yesno"+this.getId()} />
                                    Ja
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className={"p-2 option"}>
                                <Label check>
                                    <Input disabled={this.isFinished()} type={"radio"} checked={this.isChecked("no")} onChange={(e) => this.onChangeOption(e, "no")} name={"yesno"+this.getId()} />
                                    Nein
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            {this.renderErrors()}
                        </Row>
                    </FormGroup>
                </Col>
            </Row>
        </Container>;
    }
};

QuestionYesNo.propTypes = {

};


export default QuestionYesNo;
