import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { FaLessThan } from 'react-icons/fa';
import QuestionPageContainer from "./QuestionPageContainer";
import QuestionYesNo from "../questiontypes/QuestionYesNo";
import QuestionMultipleChoice from "../questiontypes/QuestionMultipleChoice";
import QuestionText from "../questiontypes/QuestionText";
import QuestionDomain from "../questiontypes/QuestionDomain";

import QuestionRichText from "../questiontypes/QuestionRichText";
import QuestionColor from "../questiontypes/QuestionColor";
import QuestionSingleChoice from "../questiontypes/QuestionSingleChoice";
import QuestionLink from "../questiontypes/QuestionLink";
import QuestionWYSIWYG from "../questiontypes/QuestionWYSIWYG";
import QuestionFile from "../questiontypes/QuestionFile";

import AirtableHelper from "../../helper/AirtableHelper";

class Question extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    qCtrl() {
        return this.props.qctrl;
    }

    question() {
        return this.props.question;
    }

    isVisibleByCondition() {
        if (this.qCtrl().checkCondition(this.question())) {
            return true;
        }
        return false;
    }

    renderQuestionByType() {
        try {
            const type = this.question().Datentyp.toLowerCase().trim();
            if (type === "yes / no") {
                return <QuestionYesNo {...this.props}></QuestionYesNo>;
            }
            else if (type === "link") {
                return <QuestionLink {...this.props}></QuestionLink>;
            }
            else if (type === "multiple-choice") {
                return <QuestionMultipleChoice {...this.props}></QuestionMultipleChoice>;
            }
            else if (type === "single-choice") {
                return <QuestionSingleChoice {...this.props}></QuestionSingleChoice>;
            }
            else if (type === "text") {
                return <QuestionText {...this.props}></QuestionText>;
            }
            else if (type === "domain") {
                return <QuestionDomain {...this.props}></QuestionDomain>;
            }
            else if (type === "rich-text") {
                return <QuestionRichText {...this.props}></QuestionRichText>;
            }
            else if (type === "color") {
                return <QuestionColor {...this.props}></QuestionColor>;
            }
            else if (type === "wysiwyg") {
                return <QuestionWYSIWYG {...this.props}></QuestionWYSIWYG>;
            }
            else if (type === "file") {
                return <QuestionFile {...this.props}></QuestionFile>;
            }

            return type;
        }
        catch (e) {
            console.log("Can not render the datatype for: ", this.question());
        }
    }

    renderBadge() {
        const q = this.question();
        try {
            if (typeof q.Badge !== "undefined" && q.Badge != null &&
              q.Badge.length > 1) {
                return <div className={"question-badge"}><span>{q.Badge}</span></div>;
            }
        }
        catch (e) {}
        return null;
    }

    renderSubText() {
        const q = this.question();
        try {
            if (typeof q.Subtext !== "undefined" && q.Subtext != null &&
                q.Subtext.length > 5) {
                return AirtableHelper.createElementByHtmlRTF(q.Subtext);
            }
        }
        catch (e) {}
        return null;
    }

    renderAdditionalCss() {
        const q = this.question();
        try {
            if (typeof q.additionalCss !== "undefined" && q.additionalCss != null) {
                return <style dangerouslySetInnerHTML={{__html: q.additionalCss}} id={"additionalCss"} type={"text/css"}></style>;
            }
        }
        catch (e) {}
        return null;
    }

    renderAdditionalHtml() {
        const q = this.question();
        try {
            if (typeof q.additionalHtml !== "undefined" && q.additionalHtml != null) {
                return <div dangerouslySetInnerHTML={{__html: this.qCtrl().replaceTemplatePlaceholders(q.additionalHtml, q)}}></div>;
            }
        }
        catch (e) {}
        return null;
    }

    render() {
        return this.isVisibleByCondition() && <Container fluid={true}>
            <Row>
                <Col className={"p-1"} md={12}>
                    <h4>{this.question().Text}</h4>
                    {this.renderAdditionalCss()}
                    {this.renderBadge()}
                    {this.renderSubText()}
                    {this.renderQuestionByType()}
                    {this.renderAdditionalHtml()}
                </Col>
            </Row>
        </Container>;
    }
};

Question.propTypes = {

};


export default Question;
