import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { FaHistory } from 'react-icons/fa';
import QuestionPageContainer from "./QuestionPageContainer";
import Lightbox from "react-image-lightbox";
import '!style-loader!css-loader!react-image-lightbox/style.css';
// Import the Slate components and React plugin.
// Import the Slate editor factory.
import ReactQuill from 'react-quill';
import Quill from "quill";
import '!style-loader!css-loader!react-quill/dist/quill.snow.css';
import Button from "reactstrap/lib/Button";
import ImageNumberMapping from "../specials/ImageNumberMapping";
let Inline = Quill.import('blots/inline');
class ReplaceBlot extends Inline { }
ReplaceBlot.blotName = 'replace';
ReplaceBlot.tagName = 'replace';
Quill.register('formats/replace', ReplaceBlot);

const formats = ["h1"]; // add custom format name + any built-in formats you need


class SamplePageContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
        };
    }

    getOptions(q) {
        return this.qCtrl().getOptions(q["Auswahlmöglichkeiten"]);
    }

    toggleOpen(clickedImage, q) {
        const index = this.getAllImagesByQuestions().findIndex((d) => d === clickedImage);
        this.setState({isOpen: true, photoIndex: index});
    }

    qCtrl() {
        return this.props.qctrl;
    }

    renderGridImages(multiimages, q) {
        let images= [];
        const firstImage = multiimages[0];
        for (let i=1; i<multiimages.length; i++) {
            const img = multiimages[i];
            images.push(<Col onClick={(e) => this.toggleOpen(img, q)} md={3} sm={6}>
                <ImageNumberMapping nr={i+1}>
                    <img className={"small-grid-image"} src={img} />
                </ImageNumberMapping>
            </Col>);
        }
        return <div className={"sample-image-container-grid"}>
                    <div onClick={(e) => this.toggleOpen(firstImage, q)} className={"sample-image-container"}>
                        <Row>
                            <Col md={12}>
                                <ImageNumberMapping nr={1}>
                                    <img src={firstImage} />
                                </ImageNumberMapping>
                            </Col>
                        </Row>
                    </div>
                    <Row >
                        {images}
                    </Row>
                </div>;
    }

    getCurrentTextOption() {
        const val = this.qCtrl().getValueForQuestion(q);
        if (val == null) {
            return null;
        }
        const option = this.qCtrl().getOptionByName(val);
        if (option == null) {
            return null;
        }
        return option;
    }

    onEditorStateChange(section, val, option, question) {

        section = parseInt(section.toString());
        const text = (this.qCtrl().hasAdditionalData(question.id)) ? this.qCtrl().getAdditionalData(question) : option["Beispiel-Text"];
        const splitText = text.replaceAll("\n", "");
        let sections = splitText.match(/<section.*?<\/section/g);
        if (sections == null || sections.length == 0) {
            sections = [val];
        }
        else {
            sections = sections.map((s) => {
                const number = s.match(/(\d+)/g)[0];
               return s + number + ">";
            });
            if (typeof sections[section] !== "undefined") {
                const curSection = sections[section];
                const number = curSection.match(/(\d+)/g)[0];
                sections[section] = curSection.match(/<section.*?>/g)[0] + val + "</section"+number+">";
            }
        }

        //if (val != option["Beispiel-Text"]) {
            this.qCtrl().appendAdditionalValue(question, sections.join(""));
        //}

    }

    resetText(option, question) {

        this.qCtrl().appendAdditionalValue(question, option["Beispiel-Text"]);
    }

    renderText(option, question) {
        // Check Type
        const design = option["Design"];
        // Design can be: "grid" oder "untereinander"
        const text = (this.qCtrl().hasAdditionalData(question.id)) ? this.qCtrl().getAdditionalData(question) : option["Beispiel-Text"];
        let rtfText = text;

        if (typeof rtfText === "undefined" || rtfText == null) {
            return null;
        }

        try {
            if (!this.qCtrl().isFinished() || this.qCtrl().isEditMode()) {

                const _this = this;
                // Split to rtf Text in different parts
                let index = 0;
                const splitText = rtfText.replaceAll("\n", "");
                let sections = splitText.match(/<section.*?<\/section/g);
                if (sections == null || sections.length == 0) {
                    sections = [rtfText];
                }
                else {
                    sections = sections.map((s) => {
                        return s.replace(/<section(.*?)\>/g, '').replace(/<\/section(.*?)/g, '');
                    });
                }
                const quillEditors = [];
                for (const i in sections) {
                    const s = sections[i];
                    quillEditors.push(<ReactQuill id={"quill"+i} theme="snow" value={s} onChange={(text, delta, source, editor) => {
                        if (source == 'user') {
                            // place whatever function you want to execute when user types here:
                            this.onEditorStateChange(i, text, option, question);
                        }
                    }}
                    />);
                }


                return  <div>
                    <Button onClick={(e) => this.resetText(option, question)} color={"None"} className={"react-quill-reset-btn"}><FaHistory size={12}/> Text zurücksetzen</Button>
                    {quillEditors}
                </div>;
            }
            else {
                return <div>
                    <span dangerouslySetInnerHTML={{__html: rtfText}}></span>
                </div>;
            }

        }
        catch (e) {
            console.error(e);
        }
    }

    renderImage(q) {
        try {
            if (Array.isArray(q)) {
                const list = q.filter((qs) => qs != null).map((qs) => {
                    if (qs != null) {
                        // Check Type
                        const design = qs["Design"];
                        // Design can be: "grid" oder "untereinander"
                        const bild = qs["Beispiel-Bild"];

                        if (bild != null && bild.toString().trim() != "") {
                            return <div onClick={(e) => this.toggleOpen(bild, qs)} className={"col-md-6 sample-image-container-small"}>
                                        <img src={bild} />
                                    </div>;
                        }
                    }
                });
                return <Row>
                    {list}
                </Row>;
            }
            else {

                // Check Type
                const design = q["Design"];
                // Design can be: "grid" oder "untereinander"
                const bild = q["Beispiel-Bild"];

                if (bild.toString().includes("\n")) {
                    // Its a multi image
                    const multiimages = bild.split("\n");
                    const images = [];
                    if (design == null || design.toLowerCase() === "untereinander") {

                        for (let img of multiimages) {
                            images.push(<div onClick={(e) => this.toggleOpen(img, q)} className={"sample-image-container"}>
                                <img src={img} />
                            </div>);
                        }
                    }
                    else {
                        return this.renderGridImages(multiimages, q);
                    }
                    return images;
                }
                else {

                    return <div onClick={(e) => this.toggleOpen(bild, q)} className={"sample-image-container"}>
                                <img src={bild} />
                            </div>;
                }
            }


        }
        catch (e) {
            return null;
        }
    }

    renderSelectedText(q) {
        const val = this.qCtrl().getValueForQuestion(q);
        if (val == null) {
            return null;
        }
        const option = this.qCtrl().getOptionByName(val);
        if (option == null) {
            return null;
        }
        return this.renderText(option, q);
    }

    renderSelectedImage(q) {
        const val = this.qCtrl().getValueForQuestion(q);
        if (val == null) {
            return null;
        }
        const option = this.qCtrl().getOptionByName(val);
        if (option == null) {
            return null;
        }
        return this.renderImage(option);
    }

    renderSamplesForQuestionsVisible() {
        const list = [];
        const questions = this.qCtrl().getCurrentQuestionsForPage();
        for (const q of questions) {
                if (this.qCtrl().checkCondition(q)) {
                    try {
                        if (q.Datentyp.toLowerCase().includes("choice")) {
                            list.push(this.renderSelectedImage(q));
                            list.push(this.renderSelectedText(q));
                        }
                    }
                    catch (e) {

                    }
                    try {
                        if (q["Beispiel-Bild"]) {
                            list.push(
                                this.renderImage(q)
                            );
                        }
                    }
                    catch (e) {

                    }
                }

        }
        return list;
    }

    getImagesForAllOptions(q) {
        const list = [];
        this.getOptions(q).map((d) => {
            if (d["Beispiel-Bild"]) {
                const bild = d["Beispiel-Bild"];
                if (bild.includes("\n")) {
                    const multiimages = bild.split("\n");
                    list.push(...multiimages);
                }
                else {
                    list.push(d["Beispiel-Bild"]);
                }
            }
        });
        return list;
    }

    getAllImagesByQuestions() {
        let list = [];
        const questions = this.qCtrl().getCurrentQuestionsForPage();
        for (const q of questions) {
            try {
                if (this.qCtrl().checkCondition(q)) {
                    if (q.Datentyp.toLowerCase().includes("choice")) {
                        list.push(...this.getImagesForAllOptions(q));
                    }
                    else if (q["Beispiel-Bild"]) {
                        list.push(
                            q["Beispiel-Bild"]
                        );
                    }
                }
            }
            catch (e) {

            }

        }

        list = list.map((m) =>
            m.replace("\"", "")
        );

        return list;
    }

    renderLightbox() {
        const images = this.getAllImagesByQuestions();

        const { photoIndex, isOpen } = this.state;

        return isOpen && (
            <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                onCloseRequest={() => this.setState({ isOpen: false })}
                onMovePrevRequest={() =>
                    this.setState({
                        photoIndex: (photoIndex + images.length - 1) % images.length,
                    })
                }
                onMoveNextRequest={() =>
                    this.setState({
                        photoIndex: (photoIndex + 1) % images.length,
                    })
                }
            />
        );
    }

    render() {
        return <Container fluid={true}>
            <Row>
                <Col className={"p-1"} md={12}>
                    {this.renderSamplesForQuestionsVisible()}
                    {this.renderLightbox()}
                </Col>
            </Row>
        </Container>
            ;
    }
};

SamplePageContainer.propTypes = {

};


export default SamplePageContainer;
