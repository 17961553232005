import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import QuestionType from "./QuestionType";
import Input from "reactstrap/lib/Input";
import FormGroup from "reactstrap/lib/FormGroup";
import { FaCheck } from 'react-icons/fa';
import ReactQuill from "react-quill";

class QuestionWYSIWYG extends QuestionType {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  getTextValue() {
    let txt = this.getValue();
    if (txt == null && this.hasVorauswahl()) {
      txt = this.getVorauswahl();
    }
    return txt;
  }

  onEditorChange(text) {
    this.setValue(text);
  }

  render() {
    return <Container fluid={true} className={"rich-text-container "+(this.hasError() ? "error" : "")}>
      <Row>
        <Col className={"p-1 wysiwyg-editor"} md={12}>
          {this.isVorauswahl() && <FaCheck className={"vorauswahl_check"} onClick={(e) => this.onVorauswahlCheckClicked(e)}/>}
          <ReactQuill id={"quill-wysiwyg"} theme="snow" value={this.getTextValue()} onChange={(text, delta, source, editor) => {
            if (source == 'user') {
              // place whatever function you want to execute when user types here:
              this.onEditorChange(text);
            }
          }}
          />
          {this.renderErrors()}
        </Col>
      </Row>
    </Container>;
  }
};

QuestionWYSIWYG.propTypes = {

};


export default QuestionWYSIWYG;
