import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import Error from "../errors/Error";
const Handlebars = require("handlebars");

export default class QuestionType extends Component {

    getValue() {
        return this.qCtrl().getValueForQuestion(this.question());
    }

    addValue(value) {
        return this.qCtrl().addValueForQuestion(this.question(), value);
    }

    isVorauswahl() {
        let txt = this.getValue();
        if (txt == null && this.hasVorauswahl()) {
            return true;
        }
        return false;
    }

    onVorauswahlCheckClicked(e) {
        this.setValue(this.getVorauswahl());
    }

    hasVorauswahl() {
        return (typeof this.question()["Vorauswahl"] !== "undefined" && this.question()["Vorauswahl"] != null);
    }

    getVorauswahl() {
        let placeholder = this.question()["Vorauswahl"];

        var template = Handlebars.compile((placeholder) ? placeholder : "");
        return template({
            zoho: this.qCtrl().getZohoCustomerData()
        });
    }


    removeValue(value) {
        return this.qCtrl().removeValueForQuestion(this.question(), value);
    }

    hasValue(valset) {
        const val = this.qCtrl().getValueForQuestion(this.question());
        if (val != null) {
            if (Array.isArray(val)){
                if (val.includes(valset)) {
                    return true;
                }
            }
        }
        return false;
    }

    isFinished() {
        return !this.qCtrl().isEditMode() && this.qCtrl().isFinished();
    }

    getError() {
        return this.qCtrl().getError(this.question());
    }

    hasError() {
        return this.qCtrl().getError(this.question()) != null;
    }

    setValueQuick(val) {
        return this.qCtrl().setValueForQuestion(this.question(), val, false);
    }

    setValue(val) {
        return this.qCtrl().setValueForQuestion(this.question(), val);
    }

    getOptions() {
        return this.qCtrl().getOptions(this.question()["Auswahlmöglichkeiten"]);
    }

    questionName() {
        return this.question().Bezeichner;
    }

    getId() {
        return  this.question().id;
    }

    placeholder() {
        let placeholder = this.question().Placeholder;

        var template = Handlebars.compile((placeholder) ? placeholder : "");
        return template({
            zoho: this.qCtrl().getZohoCustomerData()
        });
    }

    qCtrl() {
        return this.props.qctrl;
    }

    question() {
        return this.props.question;
    }

    renderErrors() {
        if (this.hasError()) {
            return <Error error={this.getError()} />;
        }
        return null;
    }

    render() {
        return null;
    }

}
