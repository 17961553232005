import AirtableService from "./AirtableService";
import QuestionController from "../controller/QuestionController";

export default class FormularConfigurationService {

    constructor(props) {
        this.api = new AirtableService();
        this.categories = [];
        this.options = {};
        this.agenturTodos = {};
        this.craftsmenTodos = {};
        this.flatQuestions = {};
    }

    static buildHierarchicalQuestions(records) {
        const acategories = {};
        for (const r of records) {
            if (typeof r.Category === "undefined" || r.Category === "") {
                continue;
            }
            if (typeof acategories[r.Category] === "undefined"){
                acategories[r.Category] = {
                    subcategories: [{
                        id: r.Subcategory,
                        name: r["Subcategory Name"],
                        elements: [r]
                    }],
                    id: r.Category,
                    name: r.Kategoriename
                };
            }
            else {
                const cat = acategories[r.Category];
                const found = cat.subcategories.findIndex((d) => d.id == r.Subcategory);
                if (found >= 0) {
                    cat.subcategories[found].elements.push(r);
                }
                else {
                    cat.subcategories.push({
                        id: r.Subcategory,
                        name: r["Subcategory Name"],
                        elements: [r]
                    });
                }
            }

        }
        return Object.values(acategories);
    }

    setElements(records, chooseElements, craftsmenTodos, agenturTodos, productsList) {
        const chooser = {};
        for (const c of chooseElements) {
            try {
                chooser[c.Name.toLowerCase()] = c;
            }
            catch (e) {}
        }

        let sc_index = 0;
        let last_category = null;
        let last_subcategory = null;
        for (const e of records) {
            try {
                if (typeof e.Subcategory !== "undefined") {
                    if (last_subcategory != e.Subcategory || last_category != e.Category) {
                        sc_index = 1;
                    }
                    if (!(typeof e["Index"] !== "undefined")) {
                        e["Index"] = sc_index;
                    }
                    sc_index++;
                    last_category = e.Category;
                    last_subcategory = e.Subcategory;
                }

            }
            catch (e) {}
        }

        const acategories = FormularConfigurationService.buildHierarchicalQuestions(records);
        this.flatQuestions = {};
        for (const r of records) {
            if (typeof r.Category !== "undefined" && typeof r.Subcategory !== "undefined" &&
                typeof r.Kategoriename !== "undefined" && r.Category != null && r.Subcategory != null) {
                this.flatQuestions[r.id] = r;
            }
            else {
                console.info("Ignoring question row: ", r);
            }
        }
        const craftsmenTodosMap = {};
        const agenturTodosMap = {};
        const productsMap = {};
        for (const c of agenturTodos) {
            try {
                agenturTodosMap[c["id"]] = c;
            }
            catch (e) {}
        }

        for (const c of productsList) {
            try {
                productsMap[c["id"]] = c;
            }
            catch (e) {}
        }
        for (const c of craftsmenTodos) {
            try {
                craftsmenTodosMap[c["id"]] = c;
            }
            catch (e) {}
        }
        this.options = chooser;
        this.products = productsMap;
        this.agenturTodos = agenturTodosMap;
        this.craftsmenTodos = craftsmenTodosMap;
        this.categories = acategories;
    }

    loadConfigAndStore(category) {
        const _this = this;
        return new Promise(function(resolve) {
             _this.api.loadConfigurationSide(null, null, category)
                 .then((records) => {
                     return _this.api.loadChooseSettings()
                         .then((chooseElements) => {
                             return _this.api.loadCraftsmenTodos()
                               .then((todos) => {
                                   return _this.api.loadAgenturTodos()
                                     .then((agenturTodos) => {
                                         return _this.api.loadProductslist()
                                           .then((products) => {
                                               _this.setElements(records, chooseElements, todos, agenturTodos, products);
                                               return resolve();
                                           });
                                     });

                               });
                         });
                 });
        });
    }

    getCraftsmenTodos() {
        return this.craftsmenTodos;
    }

    getAgenturTodos() {
        return this.agenturTodos;
    }

    getProducts() {
        return this.products;
    }

    getOptions() {
        return this.options;
    }

    getFlatQuestionsSorted() {
        return Array.from(Object.values(this.flatQuestions)).sort((a,b) => QuestionController.sortQuestions(a,b));
    }

    getFlatQuestions() {
        return this.flatQuestions;
    }

    getCategories() {
        return this.categories;
    }


}
