import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { FaLessThan,FaTrash, FaDownload } from 'react-icons/fa';
import QuestionType from "./QuestionType";
import Input from "reactstrap/lib/Input";
import FormGroup from "reactstrap/lib/FormGroup";
import { FaCheck } from 'react-icons/fa';
import Button from "reactstrap/lib/Button";
import { FileManager, FileUploader } from "reactjs-file-uploader";
import { DOWNLOAD_API, UPLOAD_API } from "../../config";
import Progress from "reactstrap/lib/Progress";
const { v4: uuidv4 } = require('uuid');

class QuestionFile extends QuestionType {
  constructor(props) {
    super(props);
    this.state = {
      files: []
    };

    this.uploadFiles = this.uploadFiles.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  getTextValue() {
    let txt = this.getValue();
    if (txt == null && this.hasVorauswahl()) {
      txt = this.getVorauswahl();
    }
    return txt;
  }

  getAllUploads() {
    let value =  this.getValue();
    if (typeof value === "undefined" || value == null) {
      return [];
    }
    try {
      const files = JSON.parse(this.getValue()).downloadFiles;
      if (typeof files !== "undefined" && files != null) {
        return files;
      }
    }
    catch (e) {

    }
    return [];
  }



  onFileUploaded(file) {

    this.setState({
      files: this.state.files.filter((f) => f.id != file.id)
    });
    this.setValue(JSON.stringify({
      downloadFiles: [{
        name: file.filename,
        type: file.type,
        id: file.id
      }]
    }));

  }

  renderAllUploadedFiles() {
    return this.getAllUploads().map((u) => {
      return <div className={"upload-file-line row"}>
        <div className={"col-md-8 filename"}> {u.name}</div>
        <div className={"col-md-4"}><Button color={"none"} onClick={(e) => this.downloadFile(u)} size={"sm"}><FaDownload /></Button> <Button  onClick={(e) => this.deleteFile(u)}  color={"none"} size={"sm"}><FaTrash /></Button></div>
      </div>;
    });
  }

  onClickUploadFile(e) {
    document.getElementById("uploadinput-"+this.getId()).click();
  }

  static fileProgress({

                        /*
                        References to the Event objects.
                        Initial state is null and each propert gets assigned on Event.
                         */
                        uploadReady,
                        uploadStart,
                        uploadProgress,
                        uploadComplete,
                        downloadStart,
                        downloadProgress,
                        downloadComplete,
                        error,
                        abort,
                        timeout,

                        /*
                        The sequential state of the request
                        enum {
                            uploadReady, uploadStart, uploadProgress, uploadComplete, downloadStart
                            downloadStart, downloadProgress, downloadComplete
                        }
                         */
                        requestState,

                        /*
                        Function references to start / abort request
                          */
                        startUpload,
                        abortRequest,

                        /*
                        Request Object reference (XMLHttpReqeust)
                         */
                        request,

                        /*
                        Response text Object (JSON)
                         */
                        response,

                        /*
                        Data of the file being uploaded (if readData props is true)
                         */
                        fileData,

                      }) {
    return (
      <div className={"upload-file-line row"}>
        <div className={"filename col-md-6"}>{this.formData.imagefile.name}</div>
        <div className={" col-md-6"}>{uploadProgress && <Progress value={uploadProgress.loaded / uploadProgress.total * 100} >{Number(uploadProgress.loaded / uploadProgress.total * 100).toFixed(2)}</Progress>}</div>
      </div>
    );
  }

  static buildID(file) {
    let filename = file.name.toString();
    if (filename.length > 100) {
      filename = filename.substring(filename.length-100, filename.length);
    }
    return QuestionFile.uniqueID()+"_"+filename;
  }

  static uniqueID() {
    return uuidv4().toString().substring(0,7);
  }

  hasUploadedFiles() {
    if (this.getAllUploads().length <= 0) {
      return false;
    }
    return true;
  }

  onFileSelected(event) {
    const fileList = Array.from(event.target.files);
    const mapppedList = fileList.map((file) => {
      return {
        filename: file.name,
        fileInfo: file,
        id: QuestionFile.buildID(file)
      };
    });

    this.setState({files: this.state.files.concat(mapppedList)});
  }

  uploadFile(file) {
    return (
      <FileUploader
        key={file.key}
        file={file}
        onUploadComplete={() => this.onFileUploaded(file)}
        autoUpload={true}
        url={UPLOAD_API}
        formData={{
          imagefile: file.fileInfo,
          id: file.id
        }}
        readFile
      >
        {QuestionFile.fileProgress}
      </FileUploader>
    );
  }


  downloadFile(file) {
    const uri = DOWNLOAD_API+file.id;
    var link = document.createElement("a");
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', file.name);
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  deleteFile(file) {
    const files = this.getAllUploads().filter((f) => f.id != file.id);
    const value = {
      downloadFiles: [
        ...files
      ]
    };
    this.setValue(JSON.stringify(value));
    return value;
  }

  uploadFiles(files) {
    const _this = this;
    return files.map((file) => _this.uploadFile(file));
  }

  getUploadingFiles() {
    return this.state.files;
  }


  render() {
    return <Container fluid={true} className={"rich-text-container "+(this.hasError() ? "error" : "")}>
      <Row>
        <Col className={"p-1"} md={12}>
          {this.isVorauswahl() && <FaCheck className={"vorauswahl_check"} onClick={(e) => this.onVorauswahlCheckClicked(e)}/>}
          {
          <div className={"upload-container"}>
            <input
              type="file"
              onChange={event => this.onFileSelected(event)}
              multiple
              id={"uploadinput-"+this.getId()}
              style={{display: "none"}}
            />
            {!this.hasUploadedFiles() && <Button color={"primary"} onClick={(e) => this.onClickUploadFile(e)} size={"sm"}>Datei hochladen</Button>}
            <div
              className={"upload-block"}>
              <FileManager
                files={this.getUploadingFiles()}
              >
                {this.uploadFiles}
              </FileManager>
            </div>
            <div className={"all-files file-upload-question"}>
              {this.renderAllUploadedFiles()}
            </div>
          </div>}
          {this.renderErrors()}
        </Col>
      </Row>
    </Container>;
  }
};

QuestionFile.propTypes = {

};


export default QuestionFile;
