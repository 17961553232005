import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { FaLessThan } from 'react-icons/fa';
import QuestionGroup from "./QuestionGroup";
import Button from "reactstrap/lib/Button";
import CommunicationComponent from "../communication/CommunicationComponent";

class QuestionPageContainer extends CommunicationComponent {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    qCtrl() {
        return this.props.qctrl;
    }

    next(e) {
        this.fireEvent("pageChanged");
        const errors = this.qCtrl().checkForAnswersBeforeStep();
        if (errors == null) {
            this.qCtrl().nextQuestionPage();
        }
    }

    summary(e) {
        this.fireEvent("pageChanged");
        const errors = this.qCtrl().checkForAnswersBeforeStep();
        if (errors == null) {
            this.props.onResultsPageClicked(e);
        }
    }

    back(e) {
        this.fireEvent("pageChanged");
        this.qCtrl().stepBack();
    }

    isLastQuestion() {
        return this.qCtrl().isCurrentLastQuestion();
    }

    render() {
        return <Container className={"questioncontainer p-0"} fluid={true}>
            <Row>
                <Col md={12}>
                    <QuestionGroup {...this.props}></QuestionGroup>
                </Col>
                <div className={"col-md-5 footer-container"}>
                    <hr/>
                    <Row>
                        <Col sm={6}>
                            <Button onClick={(e) => this.back(e)} className={"back-button"} color={"None"}>&lt; Zurück</Button>
                        </Col>
                        <Col sm={6} >
                            {!this.isLastQuestion() && <Button  onClick={(e) => this.next(e)} color={"primary"}  className={"float-right"}>Weiter</Button>}
                            {this.isLastQuestion() && <Button  onClick={(e) => this.summary(e)} color={"primary"}  className={"float-right"}>Zusammenfassung</Button>}
                        </Col>
                    </Row>
                </div>
            </Row>
        </Container>
            ;
    }
};

QuestionPageContainer.propTypes = {

};


export default QuestionPageContainer;
