import React, {Component} from 'react';
import Container from "reactstrap/lib/Container";
import Button from "reactstrap/lib/Button";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Input from "reactstrap/lib/Input";
import ZohoFlowService from "../../services/ZohoFlowService";
import LoadingOverlay from "react-loading-overlay";
import { SHOW_PRICE_TABLE, SHOW_WEBSITE_BUY, WEBCONFIG_TRADE_MODE } from "../../config";

export default class CategoryOrderConfirmation extends Component {

  constructor(props) {
    super(props);

    this.state = {
      waiting: false,
      order: false,
      privacy: false,
      hiddenAction: false,
      avContract: false,
      contract: false,
      paymentType: null
    };

  }

  hasAllCategoryQuestionsFinished() {
    return this.qCtrl().hasAnsweredAllQuestions();
  }

  qCtrl() {
    return this.props.qctrl;
  }

  setValueFor(param, state) {
    this.state[param] = state;
    this.setState(this.state);
  }

  hasValue(param, val) {

    return this.state[param] == val;
  }
  hasAnsweredCheckboxes() {
    if (this.isFinished()) return true;
    return this.state.avContract && this.state.order && this.hasPayment() &&
      this.state.privacy && this.state.contract;
  }

  isCompleteFinished() {
    return this.qCtrl().isFinished() && !this.qCtrl().isOnlyTestFinished();
  }

  isFinished() {
    return this.qCtrl().isFinished();
  }

  createOrder() {
    this.zoho = new ZohoFlowService();
    this.setState({waiting: true});
    this.zoho.sendDataToZohoOnlyCategory(this.qCtrl(), {
      orderType: WEBCONFIG_TRADE_MODE,
      webconfigurator_templates: this.qCtrl().getWebconfiguratorTemplates(),
      categoryMode: this.qCtrl().getCurrentCategoryMode(),
      paymentType: this.state.paymentType,
      avContract: this.state.avContract,
      privacy: this.state.privacy,
      contract: this.state.contract,
      orderStatus: "ordered"
    })
      .then(() => {
        this.setState({waiting: false});
      })
      .catch((err) => {
        this.setState({waiting: false});
      });
  }

  render() {
    //   {this.state.hiddenAction && <Button onClick={(e) => this.createOrderWithoutConfirmation()} color={"secondary"} disabled={!this.hasAllQuestionsFinished() || this.isFinished()} size={"xs"}>Beauftragen zur Prüfung</Button>}
    const waitingContainer = this.state.waiting && <LoadingOverlay
      active={this.state.waiting }
      spinner
      className={"overload-spinner"}
      text='Schicke Antwort ...'
    >
      <div className={"fullscreen-loader"}><br/></div>
    </LoadingOverlay>;
    const id = this.qCtrl().getId();
    return <Container fluid={true} className={"order-page"}>
      {waitingContainer}
      <Row className={"p-4"}>
        <Col md={12} className={"p-0"}>
          <h1>Kategorie {this.qCtrl().getCurrentCategoryModeUpperCase()} speichern</h1>
          <div>Du speicherst jetzt die Kategorie {this.qCtrl().getCurrentCategoryModeUpperCase()}. </div>
          <div><br/>Bitte übermittle dem Kontakter die Information, dass Jobs gespeichert wurden.<br/><br/> </div>

          <Button disabled={!this.hasAllCategoryQuestionsFinished()} onClick={(e) => this.createOrder()} color={"primary"} size={"xs"}>Jetzt {this.qCtrl().getCurrentCategoryModeUpperCase()} speichern</Button>

        </Col>
      </Row>
    </Container>;
  }
}
