import React, { Component } from "react";
import { Button, Container, Form, Input, Label } from "reactstrap";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import LoadingOverlay from "react-loading-overlay";
import ResultsTodos from "../components/results/ResultTodos";
import ResultsAnswers from "../components/results/ResultAnswers";
import DBService from "../services/DBService";
import axios from "axios";
import { DB_API, DB_LOGIN_API } from "../config";


export default class LoginPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: "",
      password: "",
      name: "",
    };

    this.dbService = null;
    this.questionController = null;

  }

  qCtrl() {
    return this.questionController;
  }

  onLoginClicked(e) {
    this.setState({
      loading: true,
      error: null
    });
    e.preventDefault();
    e.stopPropagation();
    return axios.post(DB_LOGIN_API+"", {
      password: this.state.password
    })
      .then((result) => {

          if (result.data != null) {
            if (result.data.error != null) {
              this.setState({error: result.data.error.message});
            }
            else {
              localStorage.setItem("webconfig_token", result.data.data.token);
              let theURL              = new URL(window.location.origin);             // create dummy url
              theURL.search           = window.location.hash.substring(1);

              let page = theURL.searchParams.get("redirect");
              window.location = page;
              window.location.reload();
            }
          }
      })
      .catch((err) => {
        if (err.response.data != null) {
          if (err.response.data.error != null) {
            this.setState({ error: err.response.data.error.message });
          }
        }
      });
  }

  onPasswordChange(e) {
    this.setState({password: e.target.value});
  }

  render() {

    return <Container fluid={false} className={"login-container h-50 d-flex justify-content-center align-items-center"}>
      <Row>
        <Col md={12}>
          <Row>
            <Col md={12}>
              <h2>Login Webconfigurator</h2>
              <small>Bitte geben Sie ein Kennwort ein um den Webconfigurator zu öffnen.</small>
            </Col>
            <Col md={12}>
              <Form onSubmit={(e) => this.onLoginClicked(e)}>
              <Row>
                <Col md={12} className={"p-3"}>
                  <Label>Passwort: <Input className={"col-md-12"} type={"password"} onChange={(e) => this.onPasswordChange(e)} /></Label> <Button onClick={(e) => this.onLoginClicked(e)}>Login</Button>
                </Col>
              </Row>
              </Form>
              <Row>
                <Col md={12}>
                  <span className={"error"}>{this.state.error}</span>
                </Col>
              </Row>
            </Col>

          </Row>

        </Col>
      </Row>
    </Container>;
  }
}
