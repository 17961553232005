import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { FaCheckCircle } from 'react-icons/fa';
import CommunicationComponent from "../communication/CommunicationComponent";

class SubNavigation extends CommunicationComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getCategories() {
        return this.props.categories;
    }

    qCtrl() {
        return this.props.qctrl;
    }

    clickOnChildCategory(e, c) {
        this.fireEvent("pageChanged");
        this.qCtrl().setCurrentQuestionPage(c);
    }

    renderChildCategories() {
        const category = this.qCtrl().getCurrentCategory();
        const list = this.qCtrl().getCurrentSubcategories().map((c) => {
            const visible = this.qCtrl().isVisible(c);
            const clickable = this.qCtrl().isClickable(c);
            const clickableClass = ((clickable) ? "clickable" : "");
            const answered = this.qCtrl().hasAnswerForQuestionOrPage(c);

            const checkOrCross = (answered) ? <FaCheckCircle size={11} className={"checkCircle"} /> : null;

            if (!visible) { return null; }

            return <div onClick={(e) => (clickable) ? this.clickOnChildCategory(e, c) : null}
                        className={"eq-col "+((this.qCtrl().getCurrentSubCategory().id == c.id) ? "active" : "")+" "+clickableClass} >
                            {c.name}{checkOrCross}
                    </div>;
        });
        return list;
    }

    render() {
        return <Container fluid={true} className={"p-1 subnav"}>
            <Row className={"eq-box"}>
                {this.renderChildCategories()}
            </Row>
        </Container>;
    }
};

SubNavigation.propTypes = {

};


export default SubNavigation;
