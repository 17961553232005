import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import QuestionType from "./QuestionType";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import Input from "reactstrap/lib/Input";

class QuestionMultipleChoice extends QuestionType {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    onOptionChanged(e, option){
        if (e.target.checked) {
            this.addValue(option.Name.toLowerCase());
        }
        else {
            this.removeValue(option.Name.toLowerCase());
        }
    }

    renderBadge(q) {
        try {
            if (typeof q.Badge !== "undefined" && q.Badge != null &&
              q.Badge.length > 1) {
                return <div className={"question-badge"}><span>{q.Badge}</span></div>;
            }
        }
        catch (e) {}
        return null;
    }

    renderOptions() {
        const list = [];
        const opts = this.getOptions();
        for (const o of opts) {

            list.push( <Row>
                            <Col md={12} className={"p-2 option"}>
                                <Label>
                                    <Input disabled={this.isFinished()} type={"checkbox"} checked={this.hasValue(o.Name.toLowerCase())} onChange={(e) => this.onOptionChanged(e, o)} name={this.questionName()} />
                                    {o.Text} {this.renderBadge(o)}
                                </Label>
                            </Col>
                        </Row> );
        }

        return list;
    }

    render() {
        return <Container fluid={true} >
            <Row>
                <Col className={"p-1"} md={12}>
                    <FormGroup className={"p-3 multiple-choice-container "+(this.hasError() ? "error" : "")}>
                        {this.renderOptions()}
                        {this.renderErrors()}
                    </FormGroup>
                </Col>
            </Row>
        </Container>;
    }
};

QuestionMultipleChoice.propTypes = {

};


export default QuestionMultipleChoice;
